<template>
  <div 
  class="mb-4 me-5 assistant-card">
    <div class="d-flex flex-column mx-4 my-3">
      <div class="d-flex align-items-center">
        <div class="pointer d-flex flex-column" @click="viewDetails()">
          <div class="d-flex align-items-center">
            <img
              class="me-4"
              width="30"
              src="/img/icons/icon-robot-wink.svg"
              alt="Robot Wink"
            />
            <div class="fs-5 fw-bold text-truncate">
              {{ assistant.name }}
            </div>
          </div>
        </div>
        <div v-if="canAccessToAssistant" class="dropdown ms-auto">
          <img
            :id="'dropdownMenuButton-' + assistant.id"
            class="pointer dropdown-toggle py-2 px-2"
            :src="require('@/assets/img/itinerario/options.svg')"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          />
          <ul
            class="dropdown-menu"
            :aria-labelledby="'dropdownMenuButton-' + assistant.id"
          >
            <li>
              <a class="dropdown-item" @click="editAssistant">
                <img
                  class="me-2"
                  :src="require('@/assets/img/projects/edit.svg')"
                />
                {{ langFilter("configure") }}
              </a>
            </li>
            <li>
              <a
                class="dropdown-item"
                :data-bs-toggle="'modal'"
                :data-bs-target="'#deleteModal-' + assistant.id"
              >
                <img
                  class="me-2"
                  :src="require('@/assets/img/projects/delete.svg')"
                />
                {{ langFilter("delete") }}
              </a>
            </li>
          </ul>
        </div>
      </div>
      <p class="description">{{ assistant.description }}</p>

      <hr />
      <div>
        <div class="d-flex">
          <span>&#8226;</span> {{ langFilter("projects") }}:
          <strong>&nbsp;{{ assistant.projects_count }}</strong>
            <span v-if="canAccessToAssistant"
              class="text-muted ms-auto pointer"
              @click="assistant.projects_count > 0 ? viewDetails('projects') : null"
            >
              {{ langFilter("see_more") }}
            </span>
          <br />
        </div>
        <div class="d-flex">
          <span>&#8226;</span> {{ langFilter("templates") }}:
          <strong>&nbsp;{{ assistant.templates_count }}</strong>
            <span v-if="canAccessToAssistant"
              class="text-muted ms-auto pointer"
              @click="assistant.templates_count > 0 ? viewDetails('templates') : null"
            >
              {{ langFilter("see_more") }}
            </span>
          <br />
        </div>
        <div v-if="!isGeneralAssistant">
          <div class="d-flex">
            <span>&#8226;</span> {{ langFilter("files") }}:
            <strong>&nbsp;{{ assistant.files_count }}</strong>
            <span v-if="canAccessToAssistant"
              class="text-muted ms-auto pointer"
              @click="assistant.files_count > 0 ? viewDetails('files') : null"
            >
              {{ langFilter("see_more") }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal para eliminar -->
  <div
    :id="'deleteModal-' + assistant.id"
    class="modal fade"
    role="dialog"
    :aria-labelledby="'deleteModalLabel-' + assistant.id"
    aria-hidden="true"
  >
    <ModalAssistant
      :assistant="assistant"
      :lang="lang"
      :title="getModalTextsTranslated.title"
      :body="getModalTextsTranslated.body"
      :loading="loading"
      :confirm-text="langFilter('confirm_delete')"
      @confirm="confirmDeleteAssistant"
      @open-preview-modal="openPreviewModal($event)"
    />
  </div>

  <ModalPreviewAssistant
    v-if="isOpenModalPreview"
    :assistant="assistant"
    :lang="lang"
    :tab="selectedTab"
    @close-modal="closeModalPreview"
  />
</template>

<script>
import axios from "axios";
import { defineAsyncComponent } from "vue";
import translationMixin from "@/mixins/translationMixin";
import generalMethodsMixin from "@/mixins/generalMethodsMixin";
import translations from "./translate/AssistantTranslate.json";

export default {
  name: "AssistantCard",
  components: {
    ModalAssistant: defineAsyncComponent(
      () => import("@/components/modal/ModalAssistant.vue")
    ),
    ModalPreviewAssistant: defineAsyncComponent(
      () => import("@/components/modal/ModalPreviewAssistant.vue")
    ),
  },
  mixins: [translationMixin, generalMethodsMixin],
  props: {
    assistant: {
      type: Object,
      required: true,
    },
    lang: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      isOpenModalPreview: false,
      selectedTab: null,
      traducciones: translations,
    };
  },
  computed: {
    isGeneralAssistant() {
      return this.assistant.general;
    },
    canAccessToAssistant() {
      const { owner_info: { is_current_user: isOwnerCurrentUser } = {} } =
        this.assistant;

      return isOwnerCurrentUser && !this.isGeneralAssistant;
    },
    getModalTextsTranslated() {
      const title = this.langFilter("confirm_delete_assistant");
      const body = this.langFilter("delete_assistant_warning");
      return { title, body };
    },
  },
  beforeUnmount() {
    this.closeModal({ elementId: `deleteModal-${this.assistant.id}` })
  },
  methods: {
    editAssistant() {
      this.$router.push({
        name: "EditAssistant",
        params: { id: this.assistant.id },
      });
    },
    confirmDeleteAssistant() {
      this.loading = true;

      axios
        .delete(
          `${process.env.VUE_APP_API_URL}/assistants/${this.assistant.id}`
        )
        .then(() => {
          window.location.reload();
        })
        .catch((error) => {
          this.loading = false;
          console.error(error);
        });
    },
    viewDetails(tab) {
      if (!this.canAccessToAssistant) return;

      const query = tab ? { tab } : {};
      this.$router.push({
        name: "AssistantDetails",
        params: { id: this.assistant.id },
        query: query,
      });
    },
    openPreviewModal(tab) {
      this.selectedTab = tab
      this.isOpenModalPreview = true;
    },
    closeModalPreview() {
      this.isOpenModalPreview = false;
    },
  },
};
</script>
