import axios from "axios";
import { Modal } from "bootstrap";
import Cookies from "js-cookie";

const LIMIT_DATE = new Date("2025/12/22 23:59:00+01:00");
const SPACE_UIC = 36931;

export default {
  methods: {
    getValidUrl(url) {
      let newUrl = window.decodeURIComponent(url);
      newUrl = newUrl.trim().replace(/\s/g, "");

      if (/^(:\/\/)/.test(newUrl)) {
        return `http${newUrl}`;
      }
      if (!/^(f|ht)tps?:\/\//i.test(newUrl)) {
        return `http://${newUrl}`;
      }
      return newUrl;
    },
    //Tracks
    getAllTracksCategories() {
      axios
        .post(`${process.env.VUE_APP_API_URL}/tracks/getAllTracksCategory`, {
          lang: this.lang,
        })
        .then((response) => {
          this.categories = response.data;
        });
    },
    getAllTracks() {
      axios
        .post(`${process.env.VUE_APP_API_URL}/tracks/getAllTracks`, {
          lang: this.lang,
          space_id: this.spaces[0].id,
        })
        .then((response) => {
          this.bestTracks = response.data
            .sort((a, b) => b.rating - a.rating)
            .slice(0, 10);
          this.allTracks = response.data.sort((a, b) => a.id - b.id);
        });
    },
    closeModal({ elementId }) {
      const myModal = Modal.getOrCreateInstance(
        document.getElementById(elementId)
      );
      myModal.hide();
    },
    getAuthorizationHeader() {
      const requestHeaders = {
        Authorization: "Bearer " + Cookies.get("token"),
      };

      return requestHeaders;
    },
    isUICSpace(spaceId) {
      return spaceId === SPACE_UIC;
    },
    isUICAfterLimitDate(spaceId) {
      const currentDate = new Date();
      return this.isUICSpace(spaceId) && currentDate > LIMIT_DATE;
    },
  },
};
