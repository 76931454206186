import fileTypeJson from "../translate/fileType.json";
const _extensionsForNewProject = ["docx"];
const _extensionsToConvert = ["pdf", "docx"];

export const fileType = {
  extensions: fileTypeJson
    .map((file) => `.${file.name.split("_")[1]}`)
    .join(", "),

  getExtension(fileName) {
    const extension = fileName?.split(".").pop();
    return extension;
  },
  getDescription(lang, extension) {
    if (!extension) return;
    if (this.isValidExtension(extension)) {
      const file = fileTypeJson.find(
        (file) => file.name.split("_")[1] === extension
      );
      return file[lang];
    }

    return;
  },
  isValidExtension(extension) {
    if (!extension) return;
    return fileTypeJson.some((file) => file.name.split("_")[1] === extension);
  },
  validateExtension({ fileName, extensions }) {
    const fileExtension = this.getExtension(fileName);
    return extensions.includes(fileExtension);
  },
  isExtensionToConvert(fileName) {
    return this.validateExtension({
      fileName,
      extensions: _extensionsToConvert,
    });
  },
  isExtensionForNewProject({ fileName }) {
    return this.validateExtension({
      fileName,
      extensions: _extensionsForNewProject,
    });
  },
};
