<template>
  <div class="new-template-body">
    <div v-if="stepNum === 0" class="newtemplate-container-div">
      <div>
        <input
          :value="myTitle"
          :placeholder="langFilter('writeTitle')"
          class="new-template-input-name"
          maxlength="58"
          minlength="1"
          type="text"
          @input="setTitle($event)"
        />

        <p class="count-title">{{ myTitle.length }}/58</p>
      </div>
    </div>
    <div v-if="stepNum === 1">
      <div class="newtemplate-container-div-assistant">
        <div class="new-template-select-container">
          <div v-if="tooltipVisible === 'expert'" class="custom-tooltip">
            {{ langFilter("expert-tooltip") }}
          </div>
          <img
            src="/img/newtemplate/icon-info-line.svg"
            @mouseenter="showTooltip('expert')"
            @mouseleave="hideTooltip()"
          />
          <label class="new-template-label">{{
            langFilter("selectAssistantExpert")
          }}</label>
          <select
            id="selected-assistant"
            v-model="selected_assistant_id"
            class="new-template-select"
            @change="selectedValueAssistant($event)"
          >
            <option value="0">{{ langFilter("selectAssistantMessage") }}</option>
            <option
              v-for="assistant in assistants"
              :key="assistant.id"
              :value="assistant.id"
            >
              {{ assistant.name }}
            </option>
          </select>
        </div>
        <p v-show="selected_assistant_id != 0" style="font-style: italic">
          <b>Descripción de asistente:</b> {{ selected_assistant_description }}
        </p>
        <div class="new-template-select-container">
          <div v-if="tooltipVisible === 'evaluator'" class="custom-tooltip">
            {{ langFilter("evaluator-tooltip") }}
          </div>
          <img
            src="/img/newtemplate/icon-info-line.svg"
            @mouseenter="showTooltip('evaluator')"
            @mouseleave="hideTooltip()"
          />
          <label class="new-template-label">{{
            langFilter("selectAssistantEvaluator")
          }}</label>
          <select
            id="selected-assistant-evaluator"
            v-model="selected_evaluator_id"
            class="new-template-select"
            @change="selectedValueAssistantEvaluator($event)"
          >
            <option value="0">{{ langFilter("selectAssistantMessage") }}</option>
            <option
              v-for="assistant in assistantsEvaluator"
              :key="assistant.id"
              :value="assistant.id"
            >
              {{ assistant.name }}
            </option>
          </select>
        </div>
        <p v-show="selected_evaluator_id != 0" style="font-style: italic">
          <b>Descripción de asistente:</b> {{ selected_evaluator_description }}
        </p>
      </div>
    </div>
    <div v-if="stepNum === 2">
      <div class="newtemplate-container-div">
        <button
          :style="`background-image: url('${templateImage}')`"
          :class="[
            { 'new-template-setedImg': templateImage !== '' },
            'newItinerary-changeImageBtn',
          ]"
          @click="$refs.file.click()"
        >
          <div v-if="templateImage !== ''" class="newItinerary-selectImg">
            <img
              :src="require('@/assets/img/track/camera.svg')"
              alt="camera logo"
            />
            <br />
            <span class="newTemplate-select-img-txt">{{
              langFilter("selectImg")
            }}</span>
          </div>
          <div v-else>{{ langFilter("changeImage") }}</div>
        </button>
      </div>
      <p class="newtemplate-max">[Max: 2MB]</p>
      <input
        ref="file"
        type="file"
        accept="image/png, image/gif, image/jpeg"
        class="hide"
        @change="uploadFile"
      />
    </div>
    <div v-show="stepNum === 3" class="newtemplate-container-div">
      <div
        v-for="(category, categoryIndex) in categories"
        :id="'category-' + category.id"
        :key="categoryIndex"
        :class="[
          {
            'newTemplate-category-div-not-selected': !categoryChecked(category),
          },
          {
            'newTemplate-category-div-selected': categoryChecked(category),
          },
          'newTemplate-category-div row pointer',
        ]"
        @click="selectCategory(category)"
      >
        <div class="newTemplate-indicator col-1">{{ categoryIndex + 1 }}.</div>
        <div class="col-9 elipsis">{{ category.name }}</div>
        <div
          :class="[
            {
              'newTemplate-checkBox-selected': categoryChecked(category),
            },
            'newTemplate-checkBox col-2',
          ]"
        />
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import translationMixin from "../../../mixins/translationMixin.js";
import axios from "axios";
export default {
  props: [
    "lang",
    "space",
    "stepNum",
    "title",
    "draftCategories",
    "template_assistant_id",
    "template_assistant_name",
    "assistant_id",
    "evaluator_id",
  ],
  data() {
    return {
      myTitle: "",
      templateImage: "",
      imageName: "",
      categories: [],
      categoriesSelected: [],
      selectMostrarAssistant: false,
      showAssistant: false,
      showAssistantEvaluator: false,
      assistants: [],
      assistantsEvaluator: [],
      selected_assistant_id: "0",
      selected_evaluator_id: "0",
      ref_selected_assistant_id: "",
      ref_selected_evaluator_id: "",
      selected_assistant_description: "",
      selected_evaluator_description: "",
      tooltipVisible: null,
      tracks: [],
      traducciones: [
        {
          name: "writeTitle",
          es: "Escribe el título aquí...",
          en: "Write the title here...",
        },
        {
          name: "selectAssistantMessage",
          es: "Seleccione asistente",
          en: "Select Assistant",
        },
        {
          name: "selectAssistantExpert",
          es: "Experto",
          en: "Expert",
        },
        {
          name: "selectAssistantEvaluator",
          es: "Evaluador",
          en: "Evaluator",
        },
        {
          name: "changeImage",
          es: "Cambiar imagen",
          en: "Change image",
        },
        {
          name: "selectImg",
          es: "Selecciona una imagen",
          en: "Select an image",
        },
        {
          name: "selectAssistant",
          es: "Selecciona el asistente evaluador asociado",
          en: "Select the associated evaluator assistant",
        },
        {
          name: "selectAssistantTitle",
          es: "El asistente evaluador asociado",
          en: "The associated evaluator assistant",
        },
        {
          name: "Search...",
          es: "Buscar...",
          en: "Search...",
        },
        {
          name: "assistantWithoutDescription",
          es: "Sin descripción",
          en: "No description",
        },
        {
          name: "expert-tooltip",
          es: "El asistente experto es quien ayudará a desarrollar el proyecto.",
          en: "The expert assistant is the one who will help develop the project.",
        },
        {
          name: "evaluator-tooltip",
          es: "El asistente evaluador es quien ayudará a evaluar el proyecto y proponer mejoras.",
          en: "The evaluator assistant is the one who will help evaluate the project and suggest improvements.",
        },
      ],
    };
  },
  created() {
    axios
      .get(
        `${process.env.VUE_APP_API_URL}/new-template/getCategories/${this.lang}`
      )
      .then((response) => {
        this.categories = response.data;
      });

    axios.get(`${process.env.VUE_APP_API_URL}/assistants`).then((response) => {
      const assistantList = response?.data;

      this.assistants =
        assistantList?.filter((item) => item.assistant_type.id == 1) ?? {};
      this.assistantsEvaluator =
        assistantList?.filter((item) => item.assistant_type.id == 2) ?? {};
    });
  },
  methods: {
    setTitle(event) {
      const data = event.target.value;
      if (data.length <= 40) {
        this.myTitle = data;
      }
      this.$emit("setTitle", data);
      if (data.length > 0) {
        this.$emit("enableNext", true);
      } else {
        this.$emit("enableNext", false);
      }
    },
    uploadFile(el) {
      const file = el.target.files[0];
      if (file.size > 2000000) {
        this.$emit("errorFileUpload");
        alert(
          "El archivo es demasiado grande, por favor selecciona uno con un peso menor a 2MB"
        );
      } else {
        const formData = new FormData();
        formData.append("file", file);
        if (this.templateImage !== "") {
          formData.append("oldImage", this.templateImage);
        }
        axios
          .post(
            `${process.env.VUE_APP_API_URL}/new-template/uploadTemplateImage`,
            formData
          )
          .then((response) => {
            this.templateImage =
              process.env.VUE_APP_API_STORAGE + response.data;
            this.$emit("setTemplateImage", response.data);
          });
      }
    },
    selectCategory(category) {
      //check if category is already selected
      if (this.categoriesSelected.includes(category)) {
        //remove category from array
        this.categoriesSelected = this.categoriesSelected.filter(
          (item) => item !== category
        );
      } else {
        this.categoriesSelected.push(category);
      }
      this.$emit("setCategories", this.categoriesSelected);
    },
    categoryChecked(category) {
      return this.categoriesSelected.includes(category);
    },
    disableAssistantSelect(el) {
      if (
        el.target.className == "new-project-select-search-container"
          ? (this.selectMostrarAssistant = true)
          : (this.selectMostrarAssistant = false)
      );
    },
    activeAssistantSelect(el) {
      if (
        el.target.tagName == "LI"
          ? (this.selectMostrarAssistant = false)
          : (this.selectMostrarAssistant = true)
      );
    },

    selectedValueAssistant() {
      if (this.selected_assistant_id != 0) {
        this.selected_assistant_description = this.assistants.find(
          (item) => item.id == this.selected_assistant_id
        )?.description;
      }

      if (!this.selected_assistant_description) {
        this.selected_assistant_description = this.langFilter(
          "assistantWithoutDescription"
        );
      }

      this.$emit("setAssistant", this.selected_assistant_id);
    },

    selectedValueAssistantEvaluator() {
      if (this.selected_evaluator_id != 0) {
        this.selected_evaluator_description = this.assistantsEvaluator.find(
          (item) => item.id == this.selected_evaluator_id
        )?.description;
      }

      if (!this.selected_evaluator_description) {
        this.selected_evaluator_description = this.langFilter(
          "assistantWithoutDescription"
        );
      }

      this.$emit("setAssistantEvaluator", this.selected_evaluator_id);
    },

    showTooltip(type) {
      this.tooltipVisible = type;
    },
    hideTooltip() {
      this.tooltipVisible = null;
    },
  },
  mounted() {
    setTimeout(() => {
      this.draftCategories.forEach((category) => {
        document.getElementById("category-" + category.id).click();
      });

      if (this.assistant_id !== "") {
        console.log(this.assistants);
        this.selected_assistant_id = this.assistant_id;
        this.selected_assistant_description = this.assistants.find(
          (item) => item.id == this.assistant_id
        )?.description;
      }

      if (!this.selected_assistant_description) {
        this.selected_assistant_description = this.langFilter(
          "assistantWithoutDescription"
        );
      }

      if (this.assistant_id == null) {
        this.selected_assistant_id = "0";
      }

      if (this.evaluator_id !== "") {
        this.selected_evaluator_id = this.evaluator_id;
        this.selected_evaluator_description = this.assistantsEvaluator.find(
          (item) => item.id == this.evaluator_id
        )?.description;
      }

      if (!this.selected_evaluator_description) {
        this.selected_evaluator_description = this.langFilter(
          "assistantWithoutDescription"
        );
      }

      if (this.evaluator_id == null) {
        this.selected_evaluator_id = "0";
      }
    }, 2000);
  },
  updated() {},
  watch: {
    title: function (newTitle) {
      this.myTitle = newTitle;
    },
  },
  mixins: [translationMixin],
};
</script>

<style lang="css" scoped>
.custom-tooltip {
  width: 300px;
  position: absolute;
  background-color: #fff;
  text-align: justify;
  padding: 10px;
  bottom: 64%;
  transform: translateX(-45%);
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  border: 1px solid #cfcfcf;
  border-radius: 5px;
}
</style>