<!-- eslint-disable vue/v-on-event-hyphenation -->
<template>
  <Error
    v-if="error.show"
    :title="error.title"
    :body="error.body"
    link="link-redirect"
    image="`<img class='cohete-espacio' id='cohete' src='/img/errors/no_autorizado.svg'>`"
    vector="/img/errors/vector_158.svg"
    vamos="permiso-volver"
    header="true"
    :lang="lang"
  />
  <img
    v-else-if="loading"
    class="loading-itinerary"
    :src="require('@/assets/img/general/carga.gif')"
  />
  <div v-else>
    <div v-if="loadingItinerary" class="loading-bg">
      <img
        class="loading-itinerary"
        :src="require('@/assets/img/general/carga.gif')"
      />
    </div>
    <right-sidebar
      :lang="lang"
      :buttons="editing ? buttonsEditing : buttons"
      :view="view"
      :projectId="projectId"
      @showView="showView($event)"
    />
    <confirmation-modal
      v-if="confirmationModal"
      :lang="lang"
      :body="bodyMsg"
      :warning="warning ? langFilter('warningTxt') : null"
      :fixed="true"
      :info="info"
      @closeConfirmation="showConfirmationModal"
      @confirm="confirmationConfirm"
    />
    <div class="itinerary-logo">
      <img
        :src="require(`@/assets/img/newitinerary/logo.svg`)"
        alt="Itinerary"
      />
      {{ langFilter("projects") }}
    </div>
    <header-itinerary
      :lang="lang"
      :space="space"
      :editing="editing"
      :project="project"
      :view="view"
      :percentage="percentage"
      :role-in-project="roleInProject"
      @setEditing="setEditing()"
      @selectProject="selectProject($event)"
      @saveItinerary="saveItinerary($event)"
      @changeProjectName="changeProjectName($event)"
      @showConfirmationModalInfo="showConfirmationModalInfo($event)"
    />
    <itinerary-data
      v-if="view !== 1 && itinerary"
      :lang="lang"
      :editing="editing"
      :itinerary="itinerary"
      :editingCount="editingCount"
      :projectId="projectId"
      :showResources="showResources"
      @updateItinerary="updateItinerary($event)"
      @showConfirmationModal="showConfirmationModal($event)"
      @hideResources="showResources = false"
      @showResources="showResources = true"
      @updateResources="updateResources($event)"
      @toDeleteResources="toDeleteResources = $event"
      @updateResourcesItinerary="updateResourcesItinerary()"
    />
    <div v-if="view === 1">
      <itinerary-users
        :lang="lang"
        :editing="true"
        :space="space"
        :users-in-project="users"
        :project-id="projectId"
        @handle-users="handleUsers($event)"
      />
      <div
        class="newProjectV2-NextButton"
        :class="{ 'nextButton-clickable': projectHasUsers }"
        @click="updateUsers"
      >
        <p class="newProject-ButtonText">
          {{ langFilter("save") }}
        </p>
      </div>
    </div>

    <smart-printing-modal
      v-if="showSmartPrinting"
      :projectId="projectId"
      @close="closeSmartPrinting"
    />
  </div>

  <alertMessage ref="alerts" :lang="lang"></alertMessage>
</template>

<script>
import axios from "axios";
import translationMixin from "../../mixins/translationMixin.js";
import Error from "../ErrorComponent.vue";
export default {
  components: {
    Error,
  },
  mixins: [translationMixin],
  props: {
    lang: {
      type: String,
      required: true,
    },
    space: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      project: {
        id: 0,
        name: "",
        percentage: 0,
      },
      loadingItinerary: false,
      percentage: 0,
      confirmationModal: false,
      projectId: this.$route.params.project_id,
      itinerary: null,
      errors: [],
      succesful: false,
      buttons: [
        "itinerary",
        "users",
        "resources",
        "smartPrinting",
        "taskManager",
      ],
      buttonsEditing: [
        "itinerary",
        "users",
        "resources",
        "smartPrinting",
        "taskManager",
        "save",
      ],
      view: 0,
      editing: false,
      showResources: false,
      editingCount: 0,
      confirmationItinerary: [],
      preView: 0,
      showSmartPrinting: false,
      myResources: [],
      toDeleteResources: [],
      loading: true,
      warning: false,
      info: false,
      bodyMsg: "deleteSure",
      users: {
        tutors: [],
        members: [],
      },
      error: {
        show: false,
        title: "permiso-title",
        body: "500-body",
      },
      traducciones: [
        {
          name: "projects",
          es: "Proyectos",
          en: "Projects",
        },
        {
          name: "warningTxt",
          es: "Hay preguntas asociadas con respuestas que no se podrán recuperar",
          en: "There are questions associated with answers that cannot be recovered",
        },
        {
          name: "save",
          es: "Guardar",
          en: "Save",
        },
        {
          name: "errorGettingUser",
          es: "Error al obtener usuario",
          en: "Error getting user",
        }
      ],
      roleInProject: 0,
    };
  },
  computed: {
    projectHasUsers() {
      const { members = [], tutors = [] } = this.users || {};
      return members.length || tutors.length;
    },
  },
  watch: {
    view: function () {
      if (this.view === 5) {
        this.saveItinerary();
        this.view = 0;
      }
    },
  },
  created() {
    setTimeout(() => {
      if (this.$route.path.includes("/itinerary/"))
        axios
          .get(
            `${process.env.VUE_APP_API_URL}/checkItineraryAccess/${this.space.id}/${this.projectId}}`
          )
          .then((res) => {
            if (res.data[0]) {
              if (res.data[1]) window.location.reload();
              else {
                this.getData();
                this.getRoleInProject();
              };
            } else {
              this.error.show = true;
            }
          });
    }, 100);
  },
  methods: {
    async getData() {
      try {
        await this.getProjectPercentage();
        await this.getProjectItinerary();
      } catch (error) {
        this.error.show = true;
        console.error("Error getting data", error);
      }
    },
    async getProjectPercentage() {
      try {
        const { data } = await axios.get(
          `${process.env.VUE_APP_API_URL}/getPercentageByProject/${this.projectId}`,
        );

        this.percentage = data;
      } catch (error) {
        throw new Error("Error getting percentage", { cause: error });
      }
    },
    async getProjectItinerary() {
      try {
        const { data } = await axios.get(
          `${process.env.VUE_APP_API_URL}/getMyProjectItinerary/${this.projectId}/${this.space.id}`,
        );

        this.project = data.project;
        this.itinerary = data.itinerary;
        this.view = 0;
        this.loading = false;
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.error.title = "permiso-title";
          this.error.body = "permiso-body";
        }
        throw new Error("Error getting itinerary", { cause: error });
      }
    },
    updateResourcesItinerary() {
      axios.put(`${process.env.VUE_APP_API_URL}/updateResourcesItinerary`, {
        toDeleteResources: this.toDeleteResources,
        project_id: this.projectId
      });
    },
    changeProjectName(name) {
      this.project.name = name;
    },
    updateResources(resources) {
      this.myResources = resources;
    },
    confirmationConfirm() {
      this.itinerary = this.confirmationItinerary;
      this.confirmation_itinerary = [];
      this.confirmationModal = false;
    },
    showConfirmationModal(data) {
      if (data) {
        if (data.answers) this.warning = data.answers;
        else this.warning = false;
        if (data.itinerary) this.confirmationItinerary = data.itinerary;
      }
      this.info = false;
      this.confirmationModal = !this.confirmationModal;
    },
    showConfirmationModalInfo(data) {
      this.info = true;
      this.bodyMsg = data;
      this.warning = false;
      this.confirmationModal = !this.confirmationModal;
    },
    saveItinerary() {
      this.editingCount++;
    },
    closeSmartPrinting() {
      this.showSmartPrinting = false;
    },
    showView(view) {
      if (view === 2) {
        this.view = 0;
        this.showResources = !this.showResources;
      } else if (view === 3) {
        this.showSmartPrinting = true;
      } else if (view === 4) {
        // this.editing = false;
        // this.view = 0;
        this.editingCount++;
      } else {
        this.view = view;
      }
    },
    selectProject(id) {
      this.view = -1;
      window.history.pushState(null, null, "/itinerary/" + id);
      this.projectId = id;
      this.getData();
      this.getRoleInProject();
    },
    async updateItinerary(itinerary) {
      this.loadingItinerary = true;
      this.itinerary = itinerary[0];
      this.editing = false;

      try {
        await axios.post(`${process.env.VUE_APP_API_URL}/updateItinerary`, {
          itinerary: itinerary[0],
          newResources: itinerary[1],
          project_id: this.projectId,
          project_name: this.project.name,
          space_id: this.space.id,
          resources: this.myResources,
          toDeleteResources: this.toDeleteResources,
        });
        await this.getData();
      } catch (error) {
        console.error("Error updating itinerary", error);
        alert("Error al guardar, asegurate de rellenar todos los campos.");
        this.editing = true;
      } finally {
        this.loadingItinerary = false;
      }
    },
    setEditing() {
      if (this.editing === false) this.editing = true;
      else this.editingCount++;
    },
    async updateUsers() {
      if (!this.projectHasUsers) return;

      try {
        this.loadingItinerary = true;
        const { members, tutors } = this.users;

        await axios.put(
          `${process.env.VUE_APP_API_URL}/projects/${this.projectId}/invitations`,
          {
            members,
            tutors,
          }
        );

        this.showAlert({ isSuccess: true });
      } catch (error) {
        this.showAlert({ isSuccess: false });
        console.error("Error updating users", error);
      } finally {
        this.loadingItinerary = false;
      }
    },
    async getRoleInProject() {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API_URL}/getRoleInProject/${this.projectId}`,
        );

        this.roleInProject = response.data;
      } catch (error) {
        alert(this.langFilter("errorGettingUser"));
        console.error("Error getting role in project");
      }
    },
    showAlert({ isSuccess }) {
      const title = isSuccess
        ? "usersSuccessfullyUpdated"
        : "errorUpdatingUser";

      const text = isSuccess ? "usersUpdatedInProject" : "Please try again later";

      this.$refs.alerts.title = title;
      this.$refs.alerts.text = text;
      this.$refs.alerts.succesful = isSuccess;

      this.openErrorsAlert();
    },
    handleUsers({ data }) {
      this.users = data;
    },
    openErrorsAlert() {
      if (this.errors.length == 0) {
        this.errors.push("error");

        this.errorTime = {
          animationDuration: "12s",
          animationName: "timebar_progress_x",
        };
        this.setTimeouts = setTimeout(() => {
          this.errors = [];
        }, 12000);
      }
    },
    closeErrorsWindow() {
      this.errors = [];
    },
  },
};
</script>