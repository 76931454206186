<template>
  <div class="tabs">
    <div
      v-for="{ value, label } in tabs"
      :key="value"
      :class="`tab ${value === selectedTab ? 'selected-tab' : ''}`"
      @click="selectTab(value)"
    >
      {{ label }}
    </div>
  </div>
</template>

<script>
import translationMixin from "../../mixins/translationMixin.js";
import "./Assets/Css/Tabs.css";

export default {
  mixins: [translationMixin],
  props: {
    tabs: {
      type: Array,
      required: true,
    },
    selectedTab: {
      type: Number,
      required: true,
    },
  },
  emits: ["selectTab"],
  methods: {
    selectTab(tabIndex) {
      this.$emit("selectTab", tabIndex);
    },
  },
};
</script>
